<template>
  <nuxt-link v-if="props.game" class="card-latest-winners" :to="gameUrl">
    <atomic-image class="card-latest-winners__img" :src="src" />
    <!-- we will add nickname later -->
    <!-- <div class="title">{{ nickname || 'Unknown' }}</div> -->
    <div>
      <div class="sub-title">{{ gameName }}</div>
      <div class="items">
        <atomic-image class="fiat-display__logo" :src="`${iconUrl}/${currency}.svg`" :defaultImage="DEFAULT_IMG" />
        <span class="item">{{ formatValue(formatedSum.amount?.toFixed(2)) }} {{ formatedSum.currency }}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
  import type { IGame, IGameImages } from '@skeleton/core/types';

  import { DEFAULT_IMG } from '@skeleton/consts/staticPath';

  const props = defineProps<{
    nickname: Maybe<string>;
    amount: number;
    currency: string;
    payout: number;
    game: IGame;
    gameName: string;
    isDemoMode: boolean;
    gameImages: IGameImages;
  }>();

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { localizePath, getImageUrl, formatBalance } = useProjectMethods();

  const gameUrl = computed(() => {
    try {
      const { identity } = props.game;
      if (!isLoggedIn.value && props.isDemoMode) return localizePath(`/game/${identity}`);
      return localizePath(`/game/${identity}?real=true`);
    } catch {
      return '';
    }
  });

  const formatedSum = computed(() => formatBalance(props.currency, props.payout));

  const { iconUrl } = useDynamicIcon('currency');

  const src = computed(() => (props.gameImages.hasOwnProperty('3x4') ? getImageUrl(props.gameImages, 'square') : ''));
</script>

<style src="~/assets/styles/components/card/latest-winners.scss" lang="scss" />
